import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { selectLocale, selectAvailableLocales } from 'models/localization';
import groupBy from 'lodash/groupBy';
import { Booth } from './types';
import { selectBoothTaxonomies } from '../events';

export const boothsAdapter = createEntityAdapter<Booth>();

export const {
  selectIds: selectBoothIds,
  selectEntities: selectBoothEntities,
  selectAll: selectAllBooths,
  selectById: selectBoothById,
} = boothsAdapter.getSelectors((state: any) => state.cms.booths);

export const selectBoothPageInfo = createSelector(
  (state: any) => state.cms.booths,
  (booths) => booths.pageInfo,
);

export const selectAllValidBooths = createSelector(
  selectAllBooths,
  (booths) => booths.filter((it) => Object.values(it.name).some((name) => name)),
);

export const selectFetchingStateByBoothId = (boothId: string) => createSelector(
  (state: any) => state.cms.booths,
  (booths) => booths.boothFetchingState[boothId],
);

export const selectBoothsByKeyword = (keyword: string, options: any = {}) => {
  const limit = options.limit || undefined;
  const lowerCaseKeyword = keyword.toLowerCase();
  return createSelector(
    [selectAllValidBooths],
    (booths) => (
      booths
        .filter((booth) => {
          const matchName = Object.values(booth.name || {})
            .some((it) => it?.toLowerCase()?.includes(lowerCaseKeyword));
          if (matchName) return true;
          const matchCategories = booth.categories
            .some((category) => (
              Object.values(category.name || {})
                .some((it) => it?.toLowerCase()?.includes(lowerCaseKeyword))
            ));
          if (matchCategories) return true;
          return false;
        })
        .slice(0, limit)
    ),
  );
};

export const filterBoothsByKeywords = (keyword: any) => {
  if (Object.keys(keyword).length === 0) return selectAllValidBooths;
  return createSelector(
    selectAllValidBooths,
    (booths) => {
      const options = Object.keys(keyword);
      return booths.filter((booth) => {
        const names = (booth.categories || [])
          .flatMap((category) => Object.values(category.name));
        return options.every((it) => names.includes(it));
      });
    },
  );
};

export const getFilterOptions = () => createSelector(
  [selectBoothTaxonomies],
  (boothTaxonomies) => {
    const grouped = groupBy(boothTaxonomies, (it) => Array.isArray(it.subcategories));
    return grouped.true || grouped.false || [];
  },
);

export const filterBoothsByFilterOptions = (selectedFilter: string[]) => {
  if (!(selectedFilter?.length > 0)) return selectAllValidBooths;
  return createSelector(
    [selectAllValidBooths, getFilterOptions()],
    (booths, filterOptions) => {
      const conditions = filterOptions
        .map((it) => (
          Array.isArray(it.subcategories) ? it.subcategories : [it])
          .filter((sub) => selectedFilter.includes(sub.id)))
        .filter((it) => it.length !== 0);

      return booths
        .filter((booth) => {
          const categoriesId = (booth.categories || []).map((it) => it.id);
          return conditions
            .every((condition) => condition.some((it) => categoriesId.includes(it.id)));
        });
    },
  );
};
