const bannerUtils = {
  toImage: (src: string, href = null) => ({
    src,
    href,
    type: 'image',
  }),
};

export const en = {
  entry: {
    to_exhibition_hall: 'Enter Exhibition Hall',
    how_to_use_oms: '<b>How to utilize</b><b>virtual expo?</b>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/af54ac89-3c38-4fd4-a2ac-06b4efa6170e.png',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/abcb7c97-1a4b-4391-992d-2ae0ff5d106b.png',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/ba8a556a-9aa1-4d54-aed1-475aeaecc54e.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/ba8a556a-9aa1-4d54-aed1-475aeaecc54e.png',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/abcb7c97-1a4b-4391-992d-2ae0ff5d106b.png',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/af54ac89-3c38-4fd4-a2ac-06b4efa6170e.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/544b17c2-2bb2-4b0a-917c-19015d7fdc86.png',
        'https://www.lifestyle-expo-k.jp/ls_jmw_cosme_top_en/',
      ),
    ],
    mobile: [],
  },
};

en.banners.mobile = [
  ...en.banners.middle,
  ...en.banners.left,
];

export const ja = {
  entry: {
    to_exhibition_hall: '展示会場はこちら',
    how_to_use_oms: '<b>はじめに</b><i>オンライン来場・商談サービスの使い方</i>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/3a605627-9ecc-42cd-a1ec-3071dfe1c969.png',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/73c773ee-956b-48a0-83e3-8724d9a5365f.png',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/b70d90d3-7f41-48fd-a9bd-0f4e8fc3d7dd.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/b70d90d3-7f41-48fd-a9bd-0f4e8fc3d7dd.png',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/73c773ee-956b-48a0-83e3-8724d9a5365f.png',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/3a605627-9ecc-42cd-a1ec-3071dfe1c969.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/09fdb3ac-18dc-48b5-a642-01faf5fafe1e.png',
        'https://www.lifestyle-expo-k.jp/ls_jmw_cosme_top/',
      ),
    ],
    mobile: [],
  },
};

ja.banners.mobile = [
  ...ja.banners.middle,
  ...ja.banners.left,
];
